// .standard-btn {
// 	width: 100%;
// 	--border-radius: 0;
// 	--border-width: 1px;
// }

// .dark-border {
// 	position: absolute;
// 	left: 0;
// 	top: 191px;
// }

// .red-border {
// 	position: absolute;
// 	right: 0;
// 	top: 218px;
// }

// .heading-logo-image-wrapper img.logo {
// 	max-height: 180px;
// 	margin-top: 22px;
// }

// .heading-logo-image-wrapper {
// 	vertical-align: middle;
// 	text-align: center;
// 	height: 220px;
// 	background-color: #93001f;
// 	/* border-bottom-left-radius: 60px; */
// }

.is-online-wrapper {
	--background: var(--ion-color-dark);

	color: white;
}
