.registered-businesses-content {
	.no-data {
		color: white;
	}
	.businesses-list-wrapper {
		background-color: var(--ion-color-dark);
		color: white;

		.item-wrapper {
			margin: 8px 5px;
		}

		.header-text {
			padding-left: 12px;
			color: var(--ion-color-primary);
			font-weight: bold;
			font-size: 14px;
		}

		// .description {
		// 	margin: 4px;
		// }

		.list-item {
			--background: var(--ion-color-dark);

			ion-icon[slot='start'] {
				margin-inline-start: 10px;
				margin-right: 16px;
				margin-left: 0px;
			}
			.icon {
				color: var(--ion-color-success);
			}
			.text {
				color: white;
			}
		}

		.city {
			color: var(--ion-color-secondary-contrast);
			font-weight: bold;
		}
	}
}
