.business-home-content {
	.jackpot-wrapper {
		margin-top: 40px;

		.jackpot-text {
			color: var(--ion-color-primary);
			text-align: center;
			font-size: 40px;
			font-weight: 700;
			margin-top: 15px;
			margin-bottom: 5px;
		}

		.jackpot-amount-text {
			color: var(--ion-color-primary);
			text-align: center;
			font-size: 50px;
			font-weight: 700;
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}

	.business-credit {
		color: white;
		text-align: center;
		vertical-align: middle;
		margin-top: 0px;

		.text {
			font-size: 20px;
		}

		.amount {
			font-size: 24px;
			font-weight: 700;
			margin-top: 0;
		}
	}

	.business-name-wrapper {
		--background: transparent;
		--border-style: none;
		position: absolute;
		ion-icon[slot='start'] {
			color: white;
			margin-right: 7px;
			font-size: 35px;
		}
	}

	ion-content ion-button {
		margin-top: 8px;
		margin-bottom: 8px;
	}
}
