.influencer-home-content {
	.user-data-wrapper {
		p {
			font-size: 1.2em;
			color: var(--ion-color-primary);
			font-weight: 700;
			margin-top: 0;
		}
	}

	.qr-section {
		.qr-description {
			font-size: 1.1em;
			color: white;
			margin-top: 0;
		}

		.qr-card-wrapper {
			margin-top: 30px;
		}

		.qr-card {
			width: 140px;
			height: 140px;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}

		.qr-code {
			width: 128px;
			height: 128px;
			margin: 6px;
		}
	}

	.credit-section {
		text-align: center;
		color: white;

		.credit-text {
			font-size: 1.3em;
			font-weight: 700;
		}

		.credit-amount {
			color: var(--ion-color-primary);
			font-size: 1.5em;
			font-weight: 700;
			margin-top: 0;
		}
	}

	.whatsapp-btn {
		--background: #25d366;
		--color: white;
	}

	.app-info {
		color: white;
	}
}
