ion-button.scan-qr {
	margin: 8px 0;
}

.content-wrapper {
	margin-top: 40px;
}

.qr-data {
	margin-top: 10px;
}
