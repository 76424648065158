:root {
	--ion-color-primary: #d49100;
	--ion-color-primary-rgb: 212, 145, 0;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #bb8000;
	--ion-color-primary-tint: #d89c1a;

	--ion-color-secondary: #d12e31;
	--ion-color-secondary-rgb: 209, 46, 49;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #b8282b;
	--ion-color-secondary-tint: #d64346;

	--ion-color-tertiary: #93001f;
	--ion-color-tertiary-rgb: 147, 0, 31;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #81001b;
	--ion-color-tertiary-tint: #9e1a35;

	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

.hidden {
	display: none;
}

.dark-border {
	position: absolute;
	left: 0;
	top: 191px;
}

.red-border {
	position: absolute;
	right: 0;
	top: 218px;
}

.heading-logo-image-wrapper img.logo {
	max-height: 180px;
	margin-top: 22px;
}

.heading-logo-image-wrapper {
	vertical-align: middle;
	text-align: center;
	height: 220px;
	background-color: #93001f;
	/* border-bottom-left-radius: 60px; */
}

.absolute-icon {
	position: absolute;
	left: 8px;
	color: var(--ion-color-dark);
}

ion-content.transparent-body {
	--background: transparent !important;
	opacity: 0 !important;
	background-color: transparent !important;
	background: rgba(0, 0, 0, 0);
}

ion-content {
	--background: #222428;
	/* --overflow: hidden; */
}

.standard-input {
	--background: transparent;
	--color: white;
	--border-color: white;
}

.standard-icon-only-btn {
	color: white;
	--background: var(--ion-color-primary);
	font-size: 12px;
}

ion-content::-webkit-scrollbar {
	display: none !important;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
}
