/* MENU RULES */
.side-menu {
	--background: var(--ion-color-dark);

	ion-item {
		--background: var(--ion-color-dark);
		color: white;
	}

	ion-list {
		--background: var(--ion-color-dark);
		padding: 0;
	}

	ion-icon {
		color: white;
		margin-right: 15px;
	}
}

ion-item[button] {
	pointer-events: auto !important;
}

.menu-content-open {
	pointer-events: auto;
}
