.customer-home-content {
	ion-grid {
		padding: 0;
	}
	.dark-border {
		position: absolute;
		left: 0;
		top: 337px;
	}

	.red-border {
		position: absolute;
		right: 0;
		top: 320px;
	}

	.top-section {
		background-color: #93001f;
		height: 320px;
		// border-bottom-left-radius: 100px;
	}

	.no-qr {
		font-weight: bold;
		font-size: 1.2em;
	}

	.mTop20 {
		margin-top: 20px;
	}

	.home-icon {
		height: 60px;
		width: 60px;
		color: white;
	}

	.qr-description {
		margin-top: 3px;
		text-align: justify;
		.this-is {
			font-weight: bold;
		}

		.present-it {
			margin-top: 7px;
		}
	}

	ion-item {
		--background: transparent;
		--color: white;
		--border-style: none;
		ion-icon {
			color: white;
			font-weight: bold;
			padding: 0;
			margin-right: 9px;
		}

		ion-label {
			font-weight: bold;
		}
	}

	.square-button {
		height: 150px;
		width: 100%;
		background-color: #22aaa1;
		margin: 0 auto;
		border-radius: 10px;
	}

	.content-text {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		color: white;
		font-weight: 500;
	}

	.my-custom-menu {
		--width: 500px;
		background-color: white;
	}

	.qr-card {
		width: 160px;
		height: 160px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.qr-code {
		height: 128px;
		width: 128px;
		margin: 15px;
	}

	p,
	h1 {
		color: white;
		margin: 0;
	}
	.user-data-wrapper {
		font-weight: bold;
		margin-left: 15px;
	}

	.jackpot-wrapper h1 {
		color: var(--ion-color-primary);
		text-align: center;
		margin-top: 15px;
		font-weight: 700;
	}
	.jackpot-wrapper h2 {
		color: white;
		text-align: center;
		font-size: 50px;
		margin-top: 5px;
		margin-bottom: 0;
	}

	.your-credit-wrapper h1 {
		color: var(--ion-color-primary);
		text-align: center;
		margin-top: 5px;
		font-weight: 700;
		font-size: 27px;
	}
	.your-credit-wrapper h2 {
		color: white;
		text-align: center;
		font-size: 25px;
		margin-top: 5px;
	}

	.description-text {
		margin-top: 30px;
	}

	.info-text-wrapper {
		margin-top: 40px;
		color: white;
	}

	.whatsapp-btn {
		--background: #25d366;
		--color: white;
	}

	.next-extraction-wrapper {
		// p {
		text-align: center;
		margin-top: 25px;

		.time-el {
			color: var(--ion-color-primary);
			font-size: 1.1em;
		}

		.small-desc {
			color: white;
		}
		// }
	}
}
