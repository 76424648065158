// .jackpot-text {
// 	color: var(--ion-color-primary);
// 	text-align: center;
// 	font-size: 40px;
// 	font-weight: 700;
// 	margin-top: 15px;
// 	margin-bottom: 5px;
// }

// .jackpot-amount-text {
// 	color: var(--ion-color-primary);
// 	text-align: center;
// 	font-size: 50px;
// 	font-weight: 700;
// 	margin-top: 5px;
// 	margin-bottom: 5px;
// }
// .business-credit {
// 	color: white;
// 	text-align: center;
// 	vertical-align: middle;
// 	margin-top: 30px;

// 	.text {
// 		font-size: 20px;
// 	}

// 	.amount {
// 		font-size: 24px;
// 		font-weight: 700;
// 		margin-top: 0;
// 	}
// }

// ion-content ion-button {
// 	margin-top: 8px;
// 	margin-bottom: 8px;
// }

// h3 {
// 	color: white;
// }

.error-wrapper {
	--background: transparent;

	.label {
		color: white;
	}
}
